import React from 'react'
import WaveSvg from './images/homepage/wave-top.svg'
import { Container, Typography } from '@mui/material'
import { Header } from './Header'
import { Footer } from './Footer'

export default function PrivacyPolicyPage() {
    return (
        <section className="app-section">
            <main>
                <Header />
                <div className="wave-top" style={{ backgroundImage: `url(${WaveSvg})` }}></div>
                <div className="privacy-policy-page" >
                    <div style={{ paddingTop: 200 }}>
                        <Container maxWidth="lg">
                            <Typography variant="h3" color="secondary" className="cool-text-shadow" gutterBottom>Viewer Attack
                                Privacy Policy</Typography>
                            <Typography variant="body1">As with most online services, you trust us with your data when you use
                                our product: Viewer Attack. This Privacy Policy is here to help you understand what
                                information we collect, how we collect it, how we store it and what we do with it. If you have
                                any questions or concerns about this policy or your data in general, email us at <a
                                    href="mailto:driedbeans@viewerattack.com" target="_blank"
                                    rel="noopener noreferrer">driedbeans@viewerattack.com</a> and we’ll get back to you as soon
                                as possible.</Typography>
                            <Typography variant="body1">We do not collect any special or sensitive categories of personal data,
                                including racial or ethnic origin, political opinions, religious or philosophical beliefs, trade
                                union membership, genetic data, biometric data for the purpose of uniquely identifying a natural
                                person, or data concerning health or concerning a natural person’s sex life or sexual
                                orientation, unless one of the exceptions of Article 9 GDPR apply. If at any time, you feel that
                                this type of information has been requested of you, or been collected from you, by the Service
                                please notify us immediately.</Typography>
                            <Typography variant="body1">As described in our <a href="./termsofservice">Terms of Service</a>, you
                                may not use our Services if you are under the age of 18; or, if you are between the ages of 14
                                and 18, you are below the age of consent in your legal jurisdiction and you do not have the
                                consent of the holder of your parental responsibility. We do not intend to collect or process
                                data from minors falling into these categories.</Typography>
                            <Typography variant="body1" gutterBottom>The last substantive update to this document (excluding
                                formatting
                                changes, typo fixes, etc.) was on <b>2022-05-10</b>.</Typography>
                            <Typography variant="h3" color="secondary" className="cool-text-shadow" gutterBottom sx={{ mt: 4 }}>Your
                                Data</Typography>
                            <Typography variant="h5" className="cool-text-shadow">Account creation</Typography>
                            <Typography variant="body1" gutterBottom>If you use our Services, we will create an account
                                automatically from your Twitch public
                                data. Any additional data related to your account will only contain data useful for the Service.
                                Additional information about your privacy and your data can be found in the following
                                sections.</Typography>
                            <Typography variant="h5" className="cool-text-shadow">Data collected</Typography>
                            <Typography variant="body1">One of our goals in the creation of this service is to provide tools to
                                help you to
                                create engaging interactive content. Some of these features are monetized in order to help us
                                keep some services free and to ensure the continued development of the many free tools we
                                provide to the gaming community. For detailed information, please refer to the <a
                                    href="viewerattack-website/src/PrivacyPolicyPage" target="_blank" rel="noopener noreferrer">Twitch
                                    Privacy Policy</a>. Below is a list of the data Twitch shares with us. This list is not
                                guaranteed to be exhaustive and we do not endorse nor take any responsibility for the content or
                                information contained therein. We do not share any of your data that these third parties share
                                with us.</Typography>
                            <ul>
                                <li>Twitch account ID, username, display name, account email and creation date;</li>
                                <li>Twitch avatar URL (hosted by Twitch).</li>
                            </ul>
                            <Typography variant="h5" className="cool-text-shadow">Billing data</Typography>
                            <Typography variant="body1" gutterBottom>Purchases made inside our Service use the Twitch virtual
                                currency “Bits”
                                and undergo the <a href="https://www.twitch.tv/p/en/legal/bits-acceptable-use" target="_blank"
                                    rel="noopener noreferrer">Twitch bits policy</a>. When opting to make a
                                purchase with us, you implicitly share some information with us that we may store indefinitely
                                for legal and tax compliance purposes.</Typography>
                            <Typography variant="h5" className="cool-text-shadow">How you can verify or view your
                                information</Typography>
                            <Typography variant="body1" gutterBottom>Pursuant to the Right of Access under Article 15 GDPR, you
                                can ask to
                                review any of the information that we have retained, how we have used it, and to whom we have
                                disclosed it at any time by contacting us as indicated in the first paragraph of this Privacy
                                Policy. Subject to certain exceptions prescribed by law, and provided we can authenticate your
                                identity, you will be given reasonable access to your personal information, and will be entitled
                                to challenge the accuracy and completeness of the information and to have it amended as
                                appropriate. You may also ask us to change your preferences regarding how we use or disclose
                                your information, or let us know that you do not wish to receive any further communication from
                                us. Pursuant to the Right of Rectification under Article 16 GDPR, you may check your information
                                to verify, update, or correct it, and to have any obsolete information removed.</Typography>
                            <Typography variant="h5" className="cool-text-shadow">Account and data deletion</Typography>
                            <Typography variant="body1" gutterBottom>You may delete your account by contacting us at <a
                                href="mailto:driedbeans@viewerattack.com"
                                target="_blank"
                                rel="noopener noreferrer">driedbeans@viewerattack.com</a>.
                                Deleting your Account according to this section ensures your Right to Erasure under Article 17
                                GDPR.
                                Upon doing so, your account will be immediately deleted and you will no longer be able to log
                                into
                                it, unless you’re going to connect the same Twitch account once again.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">Contact</Typography>
                            <Typography variant="body1">Again, if you have any questions regarding this Privacy Policy, please
                                contact us at <a href="mailto:driedbeans@viewerattack.com" target="_blank"
                                    rel="noopener noreferrer">driedbeans@viewerattack.com</a>. Thank
                                you.</Typography>
                        </Container>
                    </div>
                </div>
                <Footer />
            </main>
        </section>
    )
}