import React from 'react'

import WaveSvg from './images/homepage/wave-top.svg'
import { Box, Container, Typography } from '@mui/material'
import { Footer } from './Footer'
import { Header } from './Header'

export default function TermsOfServicePage() {
    return (
        <section className="app-section">
            <main>
                <Header/>
                <div className="wave-top" style={{ backgroundImage: `url(${WaveSvg})` }}></div>
                <div className="term-of-service-page">
                    <div style={{ paddingTop: 200 }}>
                        <Container maxWidth="lg">
                            <Typography variant="h3" color="secondary" className="cool-text-shadow" gutterBottom>
                                Viewer Attack Terms of Services</Typography>
                            <Typography variant="h5" className="cool-text-shadow">The Gist</Typography>
                            <Typography variant="body1">
                                We, the creators of Viewer Attack, have the goal of helping content creators connect
                                with their
                                viewers with engaging interactive live contents. We hope that you like our projects!
                                However, to
                                ensure your safety and ours, there are limitations on how you may use these Services.
                                These
                                limitations are the Terms of Service.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                The last substantive update to this document (excluding formatting changes, typo fixes,
                                etc.)
                                was on <strong>2024-07-29</strong>.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">1. Terms of Service</Typography>
                            <Typography variant="body1">
                                These Terms of Service (“Terms”), which include and hereby incorporate the Privacy
                                Policy
                                (“Privacy Policy”), are a legal agreement between us (“Viewer Attack”, “us” or “we”) and
                                you
                                (“you”, “the User”, “the Broadcaster”, “the Streamer”). By using or accessing the
                                Service
                                (Twitch extension and website), APIs, applications, or services offered by us, which are
                                collectively referred to as the “Service,” you agree (i) that you are 18 years of age or
                                older,
                                (ii) if you are the age of majority in your jurisdiction or over, that you have read,
                                understood, and accept to be bound by the Terms, and (iii) if you are between 18 and the
                                age of
                                majority in your jurisdiction, that your legal guardian has reviewed and agrees to these
                                Terms.
                            </Typography>
                            <Typography variant="body1">
                                We reserve the right, in its sole discretion, to modify or revise these Terms at any
                                time, and
                                you agree to be bound by such modifications or revisions. Any such change or
                                modification will
                                be effective immediately upon posting on the Service, and your continued use of the
                                Service
                                after any changes or modifications to these Terms are posted will constitute your
                                acceptance of,
                                and agreement to, such changes or modifications. If you object to any change or
                                modification,
                                your sole recourse shall be to cease using the Service.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Please read these Terms carefully before accessing or using our Services. By accessing
                                or using
                                any part of our Services, you agree to become bound by the Terms set forth herein. If
                                you do not
                                agree to all the terms and conditions of this agreement, then you may not access or use
                                the
                                Service.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">2. Access to Service</Typography>
                            <Typography variant="body1">
                                The Service provides online desktop and mobile platforms designed to help you create
                                engaging
                                interactive content for your viewers. The Service may allow you to generate, create, or
                                upload
                                your own original content. Subject to your compliance with these Terms, we grant you a
                                limited,
                                revocable, non-exclusive, non-transferable, non-sublicensable license to use and access
                                the
                                Service, solely for your personal use.
                            </Typography>
                            <Typography variant="body1">
                                You agree not to (and not to attempt to) (i) use the Service for any use or purpose
                                other than
                                as expressly permitted by these Terms, (ii) access any and all non API based services in
                                a
                                manner that sends more request messages to the Service’s servers in a given period of
                                time than
                                a human can reasonably produce in the same period by using a conventional on-line web
                                browser,
                                or (ii) copy, adapt, modify, prepare derivative works based upon, distribute, license,
                                sell,
                                transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise
                                exploit
                                the Service or any portion of the Service, except as expressly permitted in these Terms.
                                No
                                licenses or rights are granted to you by implication or otherwise under any intellectual
                                property rights owned or controlled by us or our licensors, except for the permissions
                                and
                                rights expressly granted in these Terms.
                            </Typography>
                            <Typography variant="body1">
                                We reserve the right to modify or discontinue, temporarily or permanently, the Service
                                (or any
                                part thereof) with or without notice. We will make all reasonable efforts to keep the
                                Service
                                online and available, however it makes no guarantees of uptime or reliability. We
                                reserve the
                                right to refuse any user access to the Services without notice for any reason or no
                                reason,
                                including, but not limited to, a violation of the Terms.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                If you violate these Terms, we reserve the right to issue you a warning regarding the
                                violation
                                or immediately terminate or suspend any or all accounts you have created using the
                                Service. You
                                agree that we do not need to provide you notice before terminating or suspending your
                                account(s), and that we may do so at our sole discretion.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">3. Responsibility of
                                Users</Typography>
                            <Typography variant="body1">
                                You are entirely responsible for the Content you produce, and any harm resulting from
                                that
                                Content. That is the case regardless of what form or media the Content takes, which
                                includes,
                                but is not limited to text, photo, video, audio, structured and unstructured data
                                formats, or
                                code. By using the Service, you represent and warrant that your Content and conduct do
                                not
                                violate these terms.
                            </Typography>
                            <Typography variant="body1">
                                You agree to be solely responsible for the images and sounds that will be broadcasted on
                                your
                                Twitch channel. When you upload an image or sound file to Viewer Attack, you agree you
                                hereby
                                grant us a worldwide, non-exclusive, royalty-free, sub licensable and transferable
                                license and
                                that the file can be used by other Viewer
                                Attack users as well on all other channels across Twitch.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                On new file uploads, the User also agrees that the submitted content does not contain
                                third
                                party copyrighted material, or material that is subject to other third party proprietary
                                rights,
                                unless the user has permission from the rightful owner of the material. The User further
                                agrees
                                to have permission that the sounds and images can be publicly uploaded, shared,
                                broadcasted on
                                the internet, and commercially used. You agree that you will not submit any images or
                                sound
                                files that contain hate speech, harassment, sexual material, illegal material or any
                                material
                                that violates copyright laws and Twitch policies. We hold the right to remove any User
                                submitted
                                Content at any time.If you delete Content, we will use reasonable efforts to remove it
                                from the
                                Service, but you acknowledge that caching or references to the Content may not be made
                                immediately unavailable. For contractual purposes, you consent to receive communications
                                from us
                                via the email address you have submitted. We may also use your email address to send you
                                other
                                messages, including information the Service and special offers. You may opt-out of such
                                email by
                                using the “unsubscribe” link in the message, or by sending an email to <a
                                href="mailto:driedbeans@viewerattack.com" target="_blank"
                                rel="noopener noreferrer">driedbeans@viewerattack.com</a>. We also recommend using the
                                proper
                                Twitch category while streaming a certain game with our Service active as a Twitch
                                extension.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">4. Responsibility of
                                Visitors</Typography>
                            <Typography variant="body1" gutterBottom>
                                We have not reviewed, and cannot review, all of the material, including computer
                                software,
                                posted to our Services, and cannot therefore be responsible for that material’s Content,
                                use or
                                effects. By operating our Services, we do not represent or imply that it endorses the
                                material
                                there posted, or that it believes such material to be accurate, useful, or non-harmful.
                                You are
                                responsible for taking precautions as necessary to protect yourself and your computer
                                systems
                                from viruses, worms, Trojan horses, and other harmful or destructive content. Our
                                Services may
                                contain content that is offensive, indecent, or otherwise objectionable, as well as
                                content
                                containing technical inaccuracies, typographical mistakes, and other errors. Our
                                Services may
                                also contain material that violates the privacy or publicity rights, or infringes the
                                intellectual property and other proprietary rights of third parties, or the downloading,
                                copying
                                or use of which is subject to additional terms and conditions, stated or unstated. We
                                disclaim
                                any responsibility for any harm resulting from the use by visitors of our Services, or
                                from any
                                downloading by those visitors of content there posted.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">5. Content Posted on Other
                                Websites</Typography>
                            <Typography variant="body1" gutterBottom>
                                We have not reviewed, and cannot review, all of the material, including computer
                                software, made
                                available through the websites and webpages to which the Service links, and that link to
                                the
                                Service. We do not have any control over those non-Service websites, and are not
                                responsible for
                                their contents or their use. By linking to a non-Service website, we do not represent or
                                imply
                                that it endorses such website. You are responsible for taking precautions as necessary
                                to
                                protect yourself and your computer systems from viruses, worms, Trojan horses, and other
                                harmful
                                or destructive content. We disclaim any responsibility for any harm resulting from your
                                use of
                                non-Service websites and webpages.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">6. Intellectual Property</Typography>
                            <Typography variant="body1">
                                This agreement does not transfer from us to you any Viewer Attack or third party
                                intellectual
                                property, and all right, title, and interest in and to such property will remain (as
                                between the
                                parties) solely with us, and all other trademarks, service marks, graphics and logos
                                used in
                                connection with our Services, are trademarks or registered trademarks of Viewer Attack
                                or its
                                licensors. Other trademarks, service marks, graphics and logos used in connection with
                                our
                                Services may be the trademarks of other third parties. Your use of our Services grants
                                you no
                                right or license to reproduce or otherwise use any Viewer Attack or third-party
                                trademarks.
                            </Typography>
                            <Typography variant="body1">
                                You agree that you shall not modify, copy, distribute, frame, reproduce, republish,
                                download,
                                scrape, display, post, transmit, or sell in any form or by any means, in whole or in
                                part, or
                                otherwise exploit the Service without our express prior written permission. You
                                acknowledge that
                                you do not acquire any ownership rights by using the Service or by accessing any Premium
                                Service
                                provided us, or any derivative works thereof and you agree not to challenge the validity
                                of any
                                of Viewer Attack’s intellectual property or its ownership thereof. All rights not
                                expressly
                                granted by these Terms are reserved by Viewer Attack and its licensors, and no license
                                is
                                granted hereunder by estoppel, implication or otherwise.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                You further agree that by submitting Content to the Service, by any method, you hereby
                                grant us
                                a world-wide, royalty-free, perpetual and non-exclusive license to reproduce, analyze,
                                modify,
                                adapt, publish, redistribute and sell the Content, both individual and aggregated from
                                the
                                global player base. This license includes any data, information or creative works which
                                result
                                from and/or are derivative of the Content. You agree that we and all our partners can
                                use and
                                publicly share, including commercial use: clips of your live stream (video and audio),
                                live
                                stream screenshots, your Twitch username, your Twitch profile picture and user specific
                                Extension data like Twitch Bits used on your channel inside Viewer Attack.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">7. Payments
                                Act</Typography>
                            <Typography variant="body1">
                                All monetary payments through “Bits” received from the Viewer Attack Twitch Extension
                                are handled solely by Twitch. Viewer Attack does not process any payments directly. By
                                using our services, you agree to abide by Twitch's payment policies and terms of
                                service. For any issues related to payments, please refer to Twitch's support and help
                                resources. Streamers receive 80% of the Bits spent within the Viewer Attack Twitch
                                extension, in accordance with Twitch's Bits policy for Twitch Extensions. For more
                                information, visit <a
                                href="https://help.twitch.tv/s/article/earning-revenue-from-bits-in-extensions"
                                target="_blank" rel="noopener noreferrer">Twitch Bits policy for Twitch Extensions</a>.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">8. Digital Millennium Copyright
                                Act</Typography>
                            <Typography variant="body1">
                                If you believe that material available on our Site, including any Content hosted on any
                                of our
                                Sites, infringes on your copyright(s), please notify us by providing a DMCA notice. Upon
                                receipt
                                of a valid and complete notice, we will remove the material and make a good faith
                                attempt to
                                contact the user who uploaded the material by email.
                            </Typography>
                            <Typography variant="body1">
                                Please follow these steps to file a notice:
                            </Typography>
                            <ol>
                                <li>
                                    Verify that the allegedly infringing material or Content is hosted by Viewer
                                    Attack. We have no control over third-party websites and/or to material or
                                    Content that is not hosted on our servers.
                                </li>
                                <li>
                                    Attempt to contact the alleged infringer directly to see if the matter
                                    can be resolved directly between you and the user.
                                </li>
                                <li>
                                    Send your complaint to <a href="mailto:driedbeans@viewerattack.com" target="_blank"
                                                              rel="noopener noreferrer">driedbeans@viewerattack.com</a> if
                                    the issue cannot be resolved directly with the user. You must include the following:
                                    <ul>
                                        <li>
                                            A physical or electronic signature of the copyright owner or a person
                                            authorized
                                            to act on their behalf;
                                        </li>
                                        <li>
                                            An identification of the copyrighted work claimed to have been infringed;
                                        </li>
                                        <li>
                                            A description of the nature and exact location of the material that you
                                            claim to
                                            infringe your copyright, in sufficient detail to permit us to find and
                                            positively identify that material. For example, we require specific
                                            identification of the allegedly infringing user and a link to the specific
                                            location that contains the material and a description of which specific
                                            portion
                                            of the location – an image, a link, the text, etc. your complaint refers to;
                                        </li>
                                        <li>
                                            Your name, address, telephone number and email address;
                                        </li>
                                        <li>
                                            A statement that you have a good faith belief that use of the material in
                                            the
                                            manner complained of is not authorized by the copyright owner, its agent, or
                                            the
                                            law;
                                        </li>
                                        <li>
                                            A statement that the information in the notification is accurate, and under
                                            penalty of perjury, that you are authorized to act on behalf of the owner of
                                            an
                                            exclusive right that is allegedly infringed.
                                        </li>
                                        <li>
                                            As required by the DMCA, we have a policy to terminate users and/or accounts
                                            that we consider to be repeat infringers. Although we don’t share the
                                            specifics
                                            of our repeat infringer policy, we believe that it strikes the right balance
                                            of
                                            protecting the rights of copyright owners as well as protecting legitimate
                                            users
                                            from wrongful termination. Please note that notices that are successfully
                                            countered, rejected on fair use grounds, or deemed to be fraudulent are not
                                            counted against a user or account.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <Typography variant="h5" className="cool-text-shadow">9. Feedback</Typography>
                            <Typography variant="body1" gutterBottom>
                                We appreciate hearing from our users and welcome your comments regarding the Service.
                                Please be
                                advised, however, that if you send to us any comments, suggestions or recommendations
                                regarding
                                the Service (“Feedback”), particularly through any “Contact” functionality on our Sites
                                or
                                through our Service, you hereby assign to us all rights, title and interest in and to
                                the
                                Feedback. Accordingly, your Feedback will not be subject to any obligation of
                                confidentiality
                                and we will not be liable to you for any use or disclosure of any Feedback. Also, we
                                will be
                                entitled and are hereby licensed to unrestricted use of the Feedback for any purpose
                                whatsoever,
                                commercial or otherwise, without compensation to you or any other person.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">10. Termination</Typography>
                            <Typography variant="body1" gutterBottom>
                                We may terminate your access to all or any part of our Services at any time, with or
                                without
                                cause, with or without notice, effective immediately. You may, as the result of
                                termination,
                                lose your account, Content and all information and data associated therewith, as
                                applicable and
                                Viewer Attack is under no obligation to compensate you for any such loss. If you wish to
                                terminate this agreement or any accounts on the Service you may have, you may simply
                                discontinue
                                using our Services. All provisions of this agreement which by their nature should
                                survive
                                termination shall survive termination, including, without limitation, ownership
                                provisions,
                                warranty, disclaimers, indemnity and limitations of liability.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">11. Disclaimer of
                                Warranties</Typography>
                            <Typography variant="body1" gutterBottom>
                                OUR SERVICES ARE PROVIDED “AS IS”. VIEWER ATTACK AND ITS SUPPLIERS AND LICENSORS HEREBY
                                DISCLAIM
                                ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE
                                WARRANTIES OF
                                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. NEITHER VIEWER
                                ATTACK
                                NOR ITS SUPPLIERS AND LICENSORS, MAKES ANY WARRANTY THAT OUR SERVICES WILL BE ERROR FREE
                                OR THAT
                                ACCESS THERETO WILL BE CONTINUOUS OR UNINTERRUPTED. YOU UNDERSTAND THAT YOU DOWNLOAD
                                FROM, OR
                                OTHERWISE OBTAIN CONTENT OR SERVICES THROUGH, OUR SERVICES AT YOUR OWN DISCRETION AND
                                RISK.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">12. Limitation of
                                Liability</Typography>
                            <Typography variant="body1" gutterBottom>
                                IN NO EVENT WILL VIEWER ATTACK, OR ITS SUPPLIERS OR LICENSORS, BE LIABLE WITH RESPECT TO
                                ANY
                                SUBJECT MATTER OF THIS AGREEMENT UNDER ANY CONTRACT, NEGLIGENCE, STRICT LIABILITY OR
                                OTHER LEGAL
                                OR EQUITABLE THEORY FOR: (I) ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES; (II) THE
                                COST OF
                                PROCUREMENT FOR SUBSTITUTE PRODUCTS OR SERVICES; (III) FOR INTERRUPTION OF USE OR LOSS
                                OR
                                CORRUPTION OF DATA; OR (IV) FOR ANY AMOUNTS THAT EXCEED THE FEES PAID BY YOU TO VIEWER
                                ATTACK
                                UNDER THIS AGREEMENT DURING THE TWELVE (12) MONTH PERIOD PRIOR TO THE CAUSE OF ACTION.
                                VIEWER
                                ATTACK SHALL HAVE NO LIABILITY FOR ANY FAILURE OR DELAY DUE TO MATTERS BEYOND THEIR
                                REASONABLE
                                CONTROL. THE FOREGOING SHALL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
                                Communications made through the Service’s email and messaging system will not constitute
                                legal
                                notice to the Site, the Service, or any of its officers, employees, agents or
                                representatives in
                                any situation where legal notice is required by contract or any law or regulation.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">13. General Representation and
                                Warranty</Typography>
                            <Typography variant="body1" gutterBottom>
                                You represent and warrant that (i) your use of our Services will be in strict accordance
                                with
                                the Viewer Attack Privacy Policy, with the Terms set forth in this agreement, and with
                                all
                                applicable laws and regulations (including without limitation any local laws or
                                regulations in
                                your country, state, city, or other governmental area, regarding online conduct and
                                acceptable
                                content, and including all applicable laws regarding the transmission of technical data
                                exported
                                from the country in which you reside) and (ii) your use of our Services will not
                                infringe or
                                misappropriate the intellectual property rights of any third party.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">14. Indemnification</Typography>
                            <Typography variant="body1" gutterBottom>
                                You agree to indemnify and hold harmless Viewer Attack, its contractors, and its
                                licensors, and
                                their respective directors, officers, employees, and agents from and against any and all
                                claims
                                and expenses, including attorneys fees, arising out of your use of our Services,
                                including but
                                not limited to your violation of the Terms of this agreement.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">15. Translation</Typography>
                            <Typography variant="body1" gutterBottom>
                                These Terms of Service were originally written in English (US). We may translate these
                                terms
                                into other languages. In the event of a conflict between a translated version of these
                                Terms of
                                Service and the English version, the English version will control.
                            </Typography>
                            <Typography variant="h5" className="cool-text-shadow">16. Attributions</Typography>
                            <Typography variant="body1" gutterBottom>
                                These Terms of Service were adapted from the <a href="https://en.wordpress.com/tos/"
                                                                                target="_blank"
                                                                                rel="noopener noreferrer">Automattic
                                Terms of Service</a>, which are made available under a <a
                                href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank"
                                rel="noopener noreferrer">CC-BY-SA 4.0 license</a>.
                            </Typography>
                            <Box sx={{ p: 1, pt: 0 }}>
                                <Typography variant="h6">Font</Typography>
                                <Typography variant="body1" gutterBottom>
                                    <a href="https://github.com/balsamiq/balsamiqsans/blob/master/OFL.txt"
                                       target="_blank"
                                       rel="noopener noreferrer">Balsamiq Sans</a> made by Balsamiq is licensed under
                                    the SIL
                                    Open
                                    Font License, Version 1.1
                                </Typography>
                            </Box>
                            <Box sx={{ p: 1, pt: 0, mb: 3 }}>
                                <Typography variant="h6">Icons</Typography>
                                <Typography variant="body1" gutterBottom>
                                    <a href="https://www.flaticon.com/free-icons/world" title="world icons">World icons
                                        created
                                        by turkkub - Flaticon</a>
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <a href="https://www.flaticon.com/free-icons/special" title="special icons">Special
                                        icons
                                        created by Kroffle - Flaticon</a>
                                </Typography>
                            </Box>
                            <Typography variant="h5" className="cool-text-shadow">Thank you!</Typography>
                            <Typography variant="body1">
                                Please direct any questions you have to <a href="mailto:driedbeans@viewerattack.com"
                                                                           target="_blank"
                                                                           rel="noopener noreferrer">driedbeans@viewerattack.com</a>
                            </Typography>
                        </Container>
                    </div>
                </div>

                <Footer/>
            </main>
        </section>
    )
}