import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './BrowserSourcePage.css'
import { useLocation } from 'react-router-dom'
import { UnityWebPage } from './UnityWebPage'

const API_URL = process.env.REACT_APP_API_URL

export default function BrowserSourcePage() {

    const [broadcaster, setBroadcaster] = useState(null)
    const [error, setError] = useState(null)
    const [uuid, setUuid] = useState(null)

    const [makeAnotherCall, setMakeAnotherCall] = useState(0)

    const location = useLocation()

    useEffect(() => {
        try {
            const id = location.pathname.replace('/browser-source-', '')
            setUuid(id)
        } catch {
            console.error('Invalid uuid.')
        }
    }, [location])

    useEffect(() => {
        let timeoutId
        if (uuid) {
            //if (!(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))) {
            const body = {
                uuid: uuid
            }
            axios({
                method: 'POST',
                url: API_URL + 'v1/get-broadcaster-bs',
                data: body,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                /*try {
                    i18n.locale = res.data.language
                } catch {
                    console.warn(`Can't set '${res.data.language}' as locale.`)
                }*/
                setBroadcaster(res.data)
            }).catch(error => {
                if (error.response && error.response.data && error.response.data.message) {
                    if (error.response.data.message === 'too_early_browser_source') {
                        timeoutId = setTimeout(() => {
                            setMakeAnotherCall(prevState => prevState + 1)
                        }, 5000)
                    } else {
                        setError(error.response.data)
                    }
                } else {
                    setError({
                        type: 'error',
                        message: 'network'
                    })
                }
            })
            /*} else {
                setError({
                    type: 'error',
                    message: 'error_smartphone'
                })
            }*/
        }

        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [uuid, makeAnotherCall])

    useEffect(() => {
        if (error) {
            console.error(error)
        }
    }, [error])

    return (
        <section className="bs-section">
            <main>
                <div className="browser-source">
                    {/*error &&
                        <div className="absolute-center" style={{ fontSize: 28, color: 'red' }}>
                            {i18n.t(`backend_reject.${error.message}`)}
                        </div>
                    */}
                    {Boolean(broadcaster) && <UnityWebPage broadcasterProp={broadcaster}></UnityWebPage>}
                </div>
            </main>
        </section>
    )


}

