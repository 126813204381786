import React from 'react'

import Logo from './images/icons/logo-con-scritta.png'
import {
    AppBar,
    Box,
    Button,
    Container,
    Toolbar,
    IconButton, Stack, Tooltip
} from '@mui/material'


const headerButtonsTitles = [/*'Home', "How to install"*/]

const DiscordIcon = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
    <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
</svg>

const TwitterXIcon = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
</svg>

export function Header() {
    return (
        <AppBar position="absolute" className="header" color="secondary" elevation={0} sx={{ background: '#ffffff33', pt: 1, lineHeight: '0' }}>
            <Container maxWidth="lg">
                <Toolbar disableGutters>
                    <a href="./">
                        <img src={Logo} height={90} width={90} alt="logo" style={{ cursor: 'pointer' }} />
                    </a>
                    <Box sx={{ flexGrow: 1, ml: 2, display: 'flex' }}>
                        {
                            headerButtonsTitles.map((page) => (
                                <Button
                                    key={page}
                                    href={`/${page.replaceAll(' ', '-').toLowerCase()}`}
                                    color="white"
                                    sx={{ my: 2, display: 'block' }}
                                >
                                    {page}
                                </Button>
                            ))}
                    </Box>
                    <Stack direction="row" alignItems="flex-start" spacing={1}>
                        <Tooltip title="Discord">
                            <IconButton size="large" target="_blank" href="https://discord.gg/JpdryWM5E3">
                                {DiscordIcon}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="X">
                            <IconButton size="large" target="_blank" sx={{ position: 'relative', top: 2 }}
                                href="https://twitter.com/ViewerAttack">
                                {TwitterXIcon}
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    )
}