import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import LuckiestGuyFont from './fonts/LuckiestGuy/LuckiestGuy-Regular.ttf'
import BalsamiqSansRegularFont from './fonts/BalsamiqSans/BalsamiqSans-Regular.ttf'
import BalsamiqSansBoldFont from './fonts/BalsamiqSans/BalsamiqSans-Bold.ttf'

import BrowserSourcePage from './BrowserSourcePage'
import HomePage from './HomePage'
import PrivacyPolicyPage from './PrivacyPolicyPage'
import TermsOfServicePage from './TermsOfServicePage'

import './App.css'
import {
    createTheme,
    ThemeProvider,
    CssBaseline
} from '@mui/material'

const theme = createTheme({
    palette: {
        primary: {
            main: '#2196f3'
        },
        secondary: {
            main: '#ff1ea5'
        },
        white: {
            main: '#ffffff'
        },
        golden: {
            main: '#faad14'
        },
        twitch: {
            main: '#8651F6'
        },
        background: {
            default: '#432b6e',
            paper: '#6441a5'
        },
        mode: 'dark'
    },
    typography: {
        fontFamily: 'BalsamiqSans-Bold'
    },
    shape: {
        borderRadius: 10
    },
    components: {
        MuiCssBaseline: {
            styleOverrides:
                `
                @font-face {
                    font-family: 'BalsamiqSans-Regular';
                    src: local('BalsamiqSans-Regular'), url(${BalsamiqSansRegularFont}) format('truetype');
                }
        
                @font-face {
                    font-family: 'BalsamiqSans-Bold';
                    src: local('BalsamiqSans-Bold'), url(${BalsamiqSansBoldFont}) format('truetype');
                }
                
                @font-face {
                    font-family: 'Luckiest Guy';
                    src: local('Luckiest Guy'), url(${LuckiestGuyFont}) format('truetype');
                }
                `
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent'
                }
            }
        }
    }
})


export default function App() {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    {/*<Route exact path="/how-to-install" element={<HowToInstallPage/>}/>*/}
                    <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
                    <Route exact path="/terms-of-service" element={<TermsOfServicePage />} />
                    <Route exact path="*" element={<BrowserSourcePage />} />
                </Routes>
            </Router>
        </ThemeProvider>
    )
}

