export const MASS_ATTACK_WAITING_TIME = 7500

export const WEB_SOCKET_ID = {
    START_MASS_ATTACK: 'START_MASS_ATTACK',
    MASS_ATTACK_SETTINGS_UPDATE: 'MASS_ATTACK_SETTINGS_UPDATE',
    UPDATE_ACTUAL_BITS: 'UPDATE_ACTUAL_BITS',
    ITEMS_SETTINGS_UPDATE: 'ITEMS_SETTINGS_UPDATE',
    TEST_BROWSER_SOURCE_SIZE: 'TEST_BROWSER_SOURCE_SIZE',
    UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
    TEST_ITEM: 'TEST_ITEM',
    ITEM_ATTACK: 'ITEM_ATTACK',
    UPDATE_MASS_ATTACK_VOLUME: 'UPDATE_MASS_ATTACK_VOLUME'
}

export const MAX_CUSTOM_ITEMS_QUANTITY = 5
export const CUSTOM_ITEM_ID = 'CUSTOM_ITEM'