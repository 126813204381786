import React from 'react'
import {
    Typography,
    Box,
    Button,
    Container,
    Toolbar,
} from '@mui/material'

const footerButtonsTitles = ['Terms of Service', 'Privacy Policy']

export function Footer() {
    return (
        <div className="footer">
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Typography
                        variant="subtitle1"
                        sx={{ flexGrow: 1 }}
                    >
                        Copyright © Dried Beans. All rights reserved
                    </Typography>
                    <Box sx={{ display: 'flex' }}>
                        {
                            footerButtonsTitles.map((page) => (
                                <Button
                                    key={page}
                                    href={`/${page.replaceAll(' ', '-').toLowerCase()}`}
                                    sx={{ color: '#B2B4BF', display: 'block', textTransform: 'none' }}
                                >
                                    {page}
                                </Button>
                            ))}
                    </Box>
                </Toolbar>
            </Container>
        </div>
    )
}