import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Container,
    Grid,
    Typography, Stack, Box
} from '@mui/material'

import { useMotionValueEvent, useScroll } from 'framer-motion'
import PooLottie from './images/lotties/poo.json'
import TomatoLottie from './images/lotties/tomato.json'
import BananaLottie from './images/lotties/egg.json'
import WaveTopSvg from './images/homepage/wave-top.svg'
import WaveBottomWhiteSvg from './images/homepage/wave-bottom-white.svg'
import WaveBottomBlueSvg from './images/homepage/wave-bottom-blue.svg'
import TwitchIcon from './images/homepage/twitch-icon.svg'
import ScreenImage from './images/homepage/viewer-attack.gif'
import PanelImage from './images/homepage/panel.webp'
import LiveImage from './images/homepage/live.webp'
import PooImage from './images/items/poo.png'
import TomatoImage from './images/items/tomato.png'
import EggImage from './images/items/egg.png'
import PiggyBankImage from './images/items/piggy_bank.png'
import MarmotImage from './images/items/marmot.png'
import SumoWrestlerImage from './images/items/sumo_wrestler.png'
import PigeonImage from './images/items/pigeon.png'
import BananaImage from './images/items/banana.png'
import CakeImage from './images/items/cake.png'
import BraImage from './images/items/bra.png'
import BitsIcon from './images/icons/bits.png'
import ChannelPointsIcon from './images/icons/channel-points.png'

import './HomePage.css'
import Lottie from 'lottie-react'
import { Footer } from './Footer'
import { Header } from './Header'
import useWindowDimensions from './useWindowDimension'


export default function HomePage() {

    const { scrollY } = useScroll()

    const tomatoLottieRef = useRef()
    const pooLottieRef = useRef()
    const bananaLottieRef = useRef()

    //Servono per gestire lo spazio tra i titoli ('How it works' e 'Increase you revenue') e il testo sotto, dato che i titoli possono andare a capo
    useWindowDimensions()
    const title1 = useRef()
    const title2 = useRef()
    const title3 = useRef()
    const [, setFirstRender] = useState(false)

    useMotionValueEvent(scrollY, 'change', (latestScrollY) => {
        const frame = latestScrollY / 15
        tomatoLottieRef.current.goToAndStop(frame, true)

        const frame2 = (latestScrollY - 400) / 15
        pooLottieRef.current.goToAndStop(frame2, true)

        const frame3 = (latestScrollY - 1000) / 15
        bananaLottieRef.current.goToAndStop(frame3, true)
    })

    useEffect(() => {
        setFirstRender(true)
    }, [])

    return (
        <section className="app-section">
            <main>
                <Header/>
                <div className="wave-top" style={{ backgroundImage: `url(${WaveTopSvg})` }}></div>
                <div className="home-page">
                    <div className="home-page-1" style={{ backgroundImage: `url(${WaveBottomWhiteSvg})` }}>
                        <Container maxWidth="lg" sx={{ height: 'calc(100vh - 160px)', display: 'flex' }}>
                            <Box
                                sx={{ position: 'absolute', bottom: -200, left: 'calc(50% - 125px)', width: 250, zIndex: 10, pointerEvents: 'none' }}>
                                <Lottie autoplay={false} lottieRef={tomatoLottieRef} animationData={TomatoLottie}/>
                            </Box>
                            <Box
                                sx={{ display: { xs: 'none', lg: 'block' }, position: 'absolute', bottom: -600, left: 0, width: 200, zIndex: 1, pointerEvents: 'none' }}>
                                <Lottie autoplay={false} lottieRef={pooLottieRef} animationData={PooLottie}/>
                            </Box>
                            <Box
                                sx={{ display: { xs: 'none', lg: 'block' }, position: 'absolute', bottom: -1200, right: 0, width: 200, zIndex: 1, pointerEvents: 'none' }}>
                                <Lottie autoplay={false} lottieRef={bananaLottieRef} animationData={BananaLottie}/>
                            </Box>
                            <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="center" alignItems="center"
                                   spacing={{ xs: 6, md: 0 }}>
                                <Grid container direction="column"
                                      alignItems={{ xs: 'center', md: 'flex-start' }}>
                                    <Typography variant="h2" className="app-title cool-text-shadow-red"
                                                sx={{ fontFamily: 'Luckiest Guy', lineHeight: '0.9', fontSize: '3.5rem' }}>
                                        VIEWER ATTACK
                                    </Typography>
                                    <Stack direction="row" alignItems="center" justifyContent="center">
                                        <Typography variant="subtitle1" className="cool-text-shadow"
                                                    sx={{ lineHeight: '1.25', fontSize: '1.5rem' }}>
                                            Let your viewers throw items at your stream!
                                            <img className="image-in-subtitle" src={TomatoImage} height={30} width={30}
                                                 alt="tomato"/>
                                            <img className="image-in-subtitle" src={EggImage} height={30} width={30}
                                                 alt="egg"/>
                                            <img className="image-in-subtitle" src={PooImage} height={30} width={30}
                                                 alt="poo"/>
                                        </Typography>
                                    </Stack>
                                    <Button variant="outlined" size="large"
                                            endIcon={<img src={TwitchIcon} alt="twitch"/>}
                                            sx={{ mt: 2, border: '2px solid white !important' }}
                                            color="white"
                                            target="_blank"
                                            href="https://dashboard.twitch.tv/extensions/hdbjnslojqku41q2c50pzdppgagxiw">
                                        Click to install&nbsp;
                                    </Button>
                                </Grid>
                                <div className="home-page-1-image-container" style={{ textAlign: 'center' }}>
                                    <img src={ScreenImage} alt="screen"/>
                                </div>
                            </Stack>
                        </Container>
                    </div>
                    <div className="home-page-2" style={{ backgroundImage: `url(${WaveBottomBlueSvg})` }}>
                        <Container maxWidth="md" sx={{ pb: 6 }}>

                            <Grid container spacing={1} className="text-container">
                                <Grid item xs={12} md={8} style={{ position: 'relative' }}>
                                    <Typography ref={title1} variant="h3"
                                                className="cool-text-shadow title-text-container-adjuster">
                                        How it works
                                    </Typography>
                                    <Typography component="div" sx={{ fontSize: 20 }}
                                                style={{ marginTop: -(title1.current ? title1.current.offsetHeight / 2 : 28) }}>
                                        Viewer Attack is a <b>Twitch extension</b> that lets your viewers interact
                                        with
                                        your stream by throwing virtual items.
                                        <br/>
                                        Once installed, your audience can access a variety of professionally
                                        animated
                                        items to interact directly from Twitch.
                                        <br/>
                                    </Typography>
                                    <Stack sx={{ mb: 3, mt: 3 }} direction="row" justifyContent="center"
                                           alignItems="center" gap={3}>
                                        <img className="image-shadow" src={PigeonImage} height={35} width={35}
                                             alt="pigeon"/>
                                        <img className="image-shadow" src={SumoWrestlerImage} height={35} width={35}
                                             alt="sumo"/>
                                        <img className="image-shadow" src={BananaImage} height={35} width={35}
                                             alt="banana"/>
                                        <img className="image-shadow" src={CakeImage} height={35} width={35}
                                             alt="cake"/>
                                        <img className="image-shadow" src={BraImage} height={35} width={35}
                                             alt="bra"/>
                                    </Stack>
                                    <Typography variant="body1" component="div" sx={{ fontSize: 20 }}>
                                        Viewer Attack is <b>fully customizable</b> and you have full control over the
                                        extension:
                                        <ul style={{ paddingLeft: 24, marginTop: 4 }}>
                                            <li>Set the optimal <b>Bits</b>
                                                <img src={BitsIcon} style={{ position: 'relative', top: 4, left: 2 }}
                                                     height={22} width={22} alt="bits"/> or&nbsp;
                                                <b>Channel Points</b><img src={ChannelPointsIcon}
                                                                          style={{ position: 'relative', top: 4, left: 4 }}
                                                                          height={18} width={18} alt="channel-points"/>
                                                &nbsp;&nbsp;prices for each item.
                                            </li>
                                            <li>Activate only the items you wish to have for your channel.</li>
                                            <li>Want to add a personal touch? Create <b>your own items</b> tailored to
                                                your
                                                channel's lore and community spirit.
                                            </li>
                                        </ul>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={4}
                                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={PanelImage} style={{ width: '100%', maxWidth: 400, borderRadius: 20 }}
                                         alt="panel"/>
                                </Grid>
                            </Grid>

                            <Box sx={{ mt: 12 }}/>

                            <Grid container justifyContent="center" alignItems="flex-start">
                                <Grid container spacing={1} item xs={12} md={8} className="text-container">
                                    <Grid item xs={12} style={{ position: 'relative' }}>
                                        <Typography ref={title2} variant="h3"
                                                    className="cool-text-shadow title-text-container-adjuster">
                                            Increase your revenue
                                        </Typography>
                                        <Typography component="div" sx={{ fontSize: 20, mb: 4 }}
                                                    style={{ marginTop: -(title2.current ? title2.current.offsetHeight / 2 : 28) }}>
                                            Your viewers are more likely to spend <b>Bits</b>
                                            <img src={BitsIcon} style={{ position: 'relative', top: 4, left: 2 }}
                                                 height={22} width={22} alt="bits"/> on your
                                            channel with
                                            Viewer
                                            Attack by engaging with you and your stream. Indeed, every month <b>thousands
                                            of Twitch streamers</b> increase their revenue thanks to Viewer Attack!
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container item sm={12} md={4} alignItems="center" justifyContent="center"
                                      sx={{ mt: { xs: 2, md: 0 } }}>
                                    < Grid item xs={6} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={PiggyBankImage} className="image-shadow"
                                             style={{ width: 180, height: 180 }}
                                             alt="pigeon"/>
                                    </Grid>
                                    <Grid item xs={6} md={12}
                                          sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>
                                        <img src={MarmotImage} className="image-shadow"
                                             style={{ width: 180, height: 180 }}
                                             alt="pigeon"/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Box sx={{ mt: 12 }}/>

                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ position: 'relative' }}>
                                    <img src={LiveImage} style={{
                                        width: '100%', borderRadius: 15,
                                        marginTop: -(title3.current ? title3.current.offsetHeight / 2 : 28)
                                    }}
                                         alt="panel"/>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                    <Box className="home-page-3">
                        <Container maxWidth="md">
                            <Typography variant="h3" className="cool-text-shadow" color="secondary"
                                        sx={{ mb: 1, color: 'white !important' }}>
                                Discover Viewer Attack
                            </Typography>
                            <div className="embed-container">
                                <iframe src="https://www.youtube.com/embed/kyxhlf1GPu0?si=l_x0OdEU2PcacSLQ"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"></iframe>
                            </div>
                        </Container>
                    </Box>
                </div>
                <Footer/>
            </main>
        </section>
    )
}